import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout';
import Rule from '../../components/rule';
import Section from '../../components/section';

import { ReactComponent as AttSvg } from '../../images/svg/projects/att.svg';
import { ReactComponent as AtlassianSvg } from '../../images/svg/projects/atlassian.svg';
import { ReactComponent as BroadcomSvg } from '../../images/svg/projects/broadcom.svg';
import { ReactComponent as CocaColaSvg } from '../../images/svg/projects/coca-cola.svg';
import { ReactComponent as EaSvg } from '../../images/svg/projects/ea.svg';
import { ReactComponent as IbmSvg } from '../../images/svg/projects/ibm.svg';
import { ReactComponent as NetflixSvg } from '../../images/svg/projects/netflix.svg';
import { ReactComponent as RocheSvg } from '../../images/svg/projects/roche.svg';
import { ReactComponent as ZyngaSvg } from '../../images/svg/projects/zynga.svg';

const projects = [
  {
    co: 'AT&T',
    desc: 'Confluence upgrade and "pluginisation"/modernisation of their customisations. Integration of Atlassian Confluence with IBM/HCL Connections.',
    Svg: AttSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'Atlassian',
    desc: 'Authoring Atlassian Jira/Confluence developer documentation and code samples e.g. for soy templating.',
    Svg: AtlassianSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'Broadcom / CA Technologies',
    desc: 'Huge multi-version & multi-language documentation site based on Atlassian Confluence.',
    Svg: BroadcomSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'Coca-Cola',
    desc: 'Intranet site based on Atlassian Confluence with many novel & custom features, including partial page templates.',
    Svg: CocaColaSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'Electronic Arts',
    desc: 'Migration and consolidation of several Atlassian Jira and Confluence instances.',
    Svg: EaSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'IBM',
    desc: 'Custom development & app integrations.',
    Svg: IbmSvg, // Svg must be capitalised otherwise the component will not render correctly
  },

  {
    co: 'Netflix',
    desc: 'Partner collaboration portal with many custom features based on Atlassian Confluence.',
    Svg: NetflixSvg,
  },

  {
    co: 'Roche',
    desc: 'Atlassian Confluence upgrade & custom development.',
    Svg: RocheSvg,
  },

  {
    co: 'Zynga',
    desc: 'Intranet and collaboration platform based on Atlassian Confluence – consolidation and migration of content from multiple systems into Confluence.',
    Svg: ZyngaSvg,
  },
];
const title = 'About David Simpson Apps: Our Company, Products & Contacts';
const yearsExperience = new Date().getFullYear() - 2008;

const CompanyPage = () => (
  <>
    <GatsbySeo
      title={title}
      description="Meet David Simpson Apps and learn a little bit about the history of our company. We think big, start small and move fast."
      language="en"
      openGraph={{
        url: 'https://www.dsapps.dev/company/',
      }}
    />
    <Layout pageTitle={title}>
      <Section className="bg-yellow-100 bg-gradient-to-br from-yellow-100 via-red-100 to-pink-100 dark:from-yellow-600 dark:via-red-600 dark:to-pink-600">
        {/* <section className="text-gray-600 body-font"> */}

        <div className="_container flex flex-col md:items-center mx-auto text-gray-900 md:flex-row dark:text-gray-100">
          <div className="w-5/6 lg:w-1/3 md:w-2/5 md:text-center">
            <StaticImage
              aspectRatio={5 / 4}
              placeholder="dominantColor"
              className="object-cover object-center rounded dark:filter dark:brightness-50 max-w-sm md:max-w-md"
              alt="Robin Hood statue at Nottingham Castle by @trommelkopf"
              title="Robin Hood statue at Nottingham Castle, Nottingham by @trommelkopf (unsplashed)"
              src="company-photo-1575757693590-4d2727e8bd50.jpg"
            />
            {/* <div className="text-xs my-2 text-center text-gray-500">
            Robin Hood statue, Nottingham Castle
          </div> */}
          </div>
          <div className="flex flex-col mt-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pl-16 md:items-start md:text-left md:mb-0 md:mt-0">
            <Rule classNames="bg-indigo-500 dark:bg-white" />
            <h1 className="mb-4 text-3xl title-font sm:text-4xl">
              David Simpson Apps is a SaaS company
            </h1>
            <p className="text-3xl">
              We create apps and integrations that add value for Atlassian &
              monday.com customers.
            </p>

            <p className="mb-8 leading-relaxed">
              Based in Robin Hood's Nottingham, England, we have over{' '}
              {yearsExperience} consecutive years experience of developing
              applications for the Atlassian ecosystem and community.
            </p>

            <p>
              <Link to="/contact/" className="button-primary">
                Contact Us
              </Link>
            </p>
          </div>
        </div>
        {/* </section> */}
      </Section>

      <Section>
        <p className="text-2xl pb-6">
          Our background is in both apps and services. We build apps and
          features for cloud native and on premise systems.
        </p>

        <h2>Selected Projects</h2>
        <section
          id="projects"
          className="overflow-hidden text-gray-600 dark:text-gray-200 body-font"
        >
          <div className="container py-6 mx-auto">
            {projects.map(({ co, desc, Svg }, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`company-project-${i}`}
                className="company-project divide-y-2"
              >
                <div className="flex flex-wrap py-4 md:flex-nowrap">
                  <div className="flex flex-col flex-shrink-0 w-56 mb-3 md:mb-0 pt-2">
                    {Svg ? <Svg /> : null}
                  </div>
                  <div className="w-full leading-relaxed lg:flex-grow">
                    <div className="font-semibold title-font">{co}</div>
                    <div>{desc}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </Section>
    </Layout>
  </>
);
// Step 3: Export your component
export default CompanyPage;
